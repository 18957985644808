import { usePermissionState } from '../context/PermissionContext'
import Loading2 from '../components/misc/loading-2'
import { useCamera } from '../hooks/useCamera'
import { Button, Box, Container, Text, Flex } from '@chakra-ui/react'
import BatteryStatus from '../components/battery/BatteryStatus'
import Download from '../components/Iconsjsx/DownloadSvg'
import { useCamBattery } from '../hooks/useCamBattery'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useEffect } from 'react'

const Camera = () => {
  const { userPermission } = usePermissionState()
  const { loading, cameras, error } = useCamera()
  const { batteryStatus, isLoadingStatus } = useCamBattery()


  useEffect(() => console.log(cameras), [cameras])
  if (loading || userPermission === null) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <Loading2 />
      </Container>
    )
  }

  if (error){
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <h1 size={30} weight="bold">
          {error}
        </h1>
    </Container>
    )
  }

  if (!userPermission || userPermission.camera !== true) {
    return (
      <Container display="flex" alignItems="center" justifyContent="center" height="100vh">
        <h1 size={30} weight="bold">
          No tienes permisos para ver esta página
        </h1>
      </Container>
    );
  }


  const downloadImage = (cam) => {
    const imageUrl = cam

    // Crea un enlace temporal
    const link = document.createElement('a')
    link.href = imageUrl
    link.download = 'image.jpg'
    link.target = '_blank'

    // Dispara un clic en el enlace para iniciar la descarga
    link.click()
  }

  return (
    <Box height="auto" marginTop={{ base: "10px", md: "60px", lg: "60px" }} marginBottom={{ base: "50px", md: "100px"}} marginLeft={{ base: "5px", md: "20px", lg: "30px" }} marginRight={{ base: "5px", md: "20px", lg: "30px" }}>
      <Flex flexDirection="row" width="100%" alignItems="center" justifyContent="start" maxWidth="1500px">
        <h1>Cameras</h1>
      </Flex>
      <Box className="camera-container">
        {cameras?.map(
          (cam, index) =>
            (cam.photoUrl && (cam.camera.type === 'service') && (
              <Container
                maxW="100%"
                key={index}
                position="relative"
                margin="0"
                padding="0"
              >
                <h4>{cam.camera.service_name}</h4>
                <Text fontSize="l" color="gray" marginBottom="5px">
                  {cam.camera.device_name}
                </Text>
                <img
                  className="imgCamera"
                  src={cam.photoUrl}
                  alt={`Última foto de la cámara ${index + 1}`}
                  style={{ maxWidth: '100%' }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  height="100px"
                  justifyContent="flex-end"
                  position="absolute"
                  top={{ base: '4%', md: '3%', lg: '4%' }}
                  right="2px"
                >
                  <Button
                    px="5px"
                    paddingBottom="5px"
                    marginX={{ base: '10px', md: '15px' }}
                    colorScheme="primary"
                    onClick={() => downloadImage(cam.photoUrl)}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="25px"
                    borderRadius="100%"
                  >
                    <Download />
                  </Button>
                </Box>
              </Container>
            ) )
            || (
              <Container
                maxW="100%"
                key={index}
                position="relative"
                margin="0"
                padding="0"
              >
                <h4>{cam.camera.service_name}</h4>
                <Text fontSize="l" color="gray" marginBottom="5px">
                  {cam.camera.device_name}
                </Text>
                <Text fontSize="m" color="gray" marginBottom="5px">
                  Not events for this camera
                </Text>
              </Container>
            )


        )}
      </Box>
    </Box>
  )
}

export default withAuthenticationRequired(Camera)


